<template>
  <form
    :id="id"
    class="zform"
    :novalidate="novalidate"
  >
    <slot />
  </form>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: undefined,
    },

    novalidate: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
